<template>
  <div>
    <head>
      <link
        rel="stylesheet"
        
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossorigin="anonymous"
      />
    </head>
    <div class="container">
      <div class="col">
        <div class="row">
          <div class="card-body">
            <h3>{{ menu_title }}</h3>
            <label>{{ rosbridge_address }}</label>
            <br />
            <button
              class="mt-2 btn btn-success"
              v-if="connected"
              @click="disconnect"
            >
              Connected!
            </button>
            <button class="mt-2 btn btn-primary" v-else @click="connect">
              Connect!
            </button>
          </div>
        </div>
        <div class="row">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="text-center">Joystick</h2>
              <div
                id="dragstartzone"
                @mousedown="startDrag"
                @mousemove="doDrag"
              ></div>
              <div id="dragCircle" :style="dragCircleStyle"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <p></p>
        </div>
        <div class="row">
          <p>
            Vertical: {{ joystick.vertical.toFixed(3) }} Horizontal:
            {{ joystick.horizontal.toFixed(3) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ROSLIB from "roslib";
import "@/resources/dashboard/Joystic/styles.css";
export default {
  props: ["dns"],

  created() {
    this.rosbridge_address = `ws://${this.dns}:9090`;
  },
  data() {
    return {
      ros: null,
      rosbridge_address: null,
      connected: false,
      // page content
      menu_title: "Connection",
      // dragging data
      dragging: false,
      x: "no",
      y: "no",
      dragCircleStyle: {
        margin: "0px",
        top: "0px",
        left: "0px",
        display: "none",
        width: "75px",
        height: "75px",
      },
      // joystick valules
      joystick: {
        vertical: 0,
        horizontal: 0,
      },
      // publisher
      pubInterval: null,
    };
  },
  methods: {
    connect: function () {
      // define ROSBridge connection object
      this.ros = new ROSLIB.Ros({
        url: this.rosbridge_address,
      });

      // define callbacks
      this.ros.on("connection", () => {
        this.connected = true;
        console.log("Connection to ROSBridge established!");
        this.pubInterval = setInterval(this.publish, 100);
      });
      this.ros.on("error", (error) => {
        console.log("Something went wrong when trying to connect");
        console.log(error);
      });
      this.ros.on("close", () => {
        this.connected = false;
        console.log("Connection to ROSBridge was closed!");
        clearInterval(this.pubInterval);
      });
    },
    publish: function () {
      let topic = new ROSLIB.Topic({
        ros: this.ros,
        name: "/cmd_vel",
        messageType: "geometry_msgs/Twist",
      });
      let message = new ROSLIB.Message({
        linear: { x: this.joystick.vertical, y: 0, z: 0 },
        angular: { x: 0, y: 0, z: this.joystick.horizontal },
      });
      topic.publish(message);
    },
    disconnect: function () {
      this.ros.close();
    },
    sendCommand: function () {
      let topic = new ROSLIB.Topic({
        ros: this.ros,
        name: "/cmd_vel",
        messageType: "geometry_msgs/Twist",
      });
      let message = new ROSLIB.Message({
        linear: { x: 1, y: 0, z: 0 },
        angular: { x: 0, y: 0, z: 0.5 },
      });
      topic.publish(message);
    },
    startDrag() {
      this.dragging = true;
      this.x = this.y = 0;
    },
    stopDrag() {
      this.dragging = false;
      this.x = this.y = "no";
      this.dragCircleStyle.display = "none";
      this.resetJoystickVals();
    },
    doDrag(event) {
      if (this.dragging) {
        this.x = event.offsetX;
        this.y = event.offsetY;
        let ref = document.getElementById("dragstartzone");
        this.dragCircleStyle.display = "inline-block";

        let minTop = ref.offsetTop - parseInt(this.dragCircleStyle.height) / 2;
        //let maxTop = minTop + 200;
        let top = this.y + minTop;
        this.dragCircleStyle.top = `${top}px`;

        let minLeft = ref.offsetLeft - parseInt(this.dragCircleStyle.width) / 2;
        //let maxLeft = minLeft + 200;
        let left = this.x + minLeft;
        this.dragCircleStyle.left = `${left}px`;

        this.setJoystickVals();
      }
    },
    setJoystickVals() {
      this.joystick.vertical = -1 * (this.y / 200 - 0.5);
      this.joystick.horizontal = +1 * (this.x / 200 - 0.5);
    },
    resetJoystickVals() {
      this.joystick.vertical = 0;
      this.joystick.horizontal = 0;
    },
  },
  mounted() {
    // page is ready
    window.addEventListener("mouseup", this.stopDrag);

    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js"
    );
    document.head.appendChild(recaptchaScript);
  },
};
</script>
<style type="text/css">
#dragstartzone {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 200px;
  border: 1px solid #333;
  border-radius: 50%;
  z-index: 10;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

#dragCircle {
  position: absolute;
  z-index: 9;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 30%);
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

#dragCircle:hover {
  background-color: lightcoral;
}

.container {
    max-width: 100%;
}
</style>
