<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters">
      <va-data-table :fields="fields"> </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        "id",
        "name",
        "phone",
        "email",
        "mainContact",
        { source: "person_id", type: "select" }
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "name", sortable: true },
        { source: "phone", sortable: true },
        { source: "email", sortable: true },
        { source: "mainContact", sortable: true },
        { source: "person_id", type: "select", sortable: true }
      ]
    };
  }
};
</script>
