<template>
  <va-edit-layout>
    <v-row>
      <v-col sm="8">
        <map-form :id="id" :title="title" :item="item"></map-form>
      </v-col>
      <!-- ===================== DADOS POINTS ========================= -->
      <v-col sm="4">
        <div v-if="this.item != null">
          <ListPoints :mapa_id="id"></ListPoints></div
      ></v-col>
    </v-row>
  </va-edit-layout>
</template>

<script>
import ListPoints from "@/resources/map/points/List.vue";

export default {
  props: ["id", "title", "item"],
  components: { ListPoints }
};
</script>
