/* eslint-disable no-undef */
import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CHECK_ERROR,
  GET_NAME,
  GET_LOGIN,
  GET_PERMISSIONS
} from "./actions";

export default (axios, params = {}) => {
  params = {
    routes: {
      login: "/login",
      logout: "/logout",
      user: "/login"
    },
    getCredentials: ({ login, password, remember }) => {
      return {
        login: login,
        password,
        remember
      };
    },
    getName: u => u.name,
    getLogin: u => u.login,
    getPermissions: u => u.roles,
    ...params
  };

  let { routes, getCredentials, getName, getLogin, getPermissions } = params;

  return {
    [LOGIN]: async ({ login, password, remember }) => {
      let response = await axios.post(
        routes.login,
        getCredentials({ login, password, remember })
      );

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      } else {
        localStorage.setItem("login", response.data.data.credencial.login);
        localStorage.setItem("token", response.data.token);
      }
      return Promise.resolve();
    },
    [LOGOUT]: async () => {
      await axios.get(routes.login);
      localStorage.removeItem("login");
      localStorage.removeItem("token");
      return Promise.resolve();
    },
    [CHECK_AUTH]: async () => {
      try {
        var token = localStorage.getItem("token");
        if (token != null) {
          var response = await axios.get("/login");
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.data;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [CHECK_ERROR]: ({ status }) => {
      if (status === 401 || status === 403) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    [GET_NAME]: user => getName(user),
    [GET_LOGIN]: user => getLogin(user),
    [GET_PERMISSIONS]: user => getPermissions(user)
  };
};
