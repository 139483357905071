<template>
  <va-form>
    <div v-if="this.imagem == null">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                Imagem
              </div>
            </template>
            <v-card-text>
              <v-file-input
                v-on:change="onImageChange"
                label="Imagem Mapa"
                filled
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-card-text>
            <v-btn color="primary" class="ma-2 white--text" @click="submit">
              Salvar
            </v-btn>
          </base-material-card></v-col
        ></v-row
      >
    </div>
    <div v-else>
      <v-row justify="center">
        <v-col sm="12"> <v-img :src="`${imagem['url']}`"></v-img> </v-col
      ></v-row>
      <v-row justify="end">
        <v-btn color="red" class="ma-2 white--text" @click="deleteImagem">
          Excluir
        </v-btn>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}
    </v-snackbar>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";

export default {
  props: ["id", "title", "item"],
  data() {
    return {
      img: [],
      imagem: null,
      snackbar: false,
      text: "",
      color: "gray"
    };
  },
  created() {
    this.getImagem();
  },
  methods: {
    getImagem() {
      try {
        service
          .getImagem({
            map_id: this.item.id
          })
          .then(resp => {
            if (resp.length > 0) {
              this.imagem = resp[0];
            }
          });
      } catch (e) {
        this.null = null;
      }
    },
    onImageChange(file) {
      let formData = new FormData();
      formData.append("image", file);
      formData.append("table", "Map");
      formData.append("table_id", this.item.id);
      formData.append("user_id", 1);
      this.img = formData;
    },
    submit() {
      return service.postImagem({ item: this.img }).then(resp => {
        try {
          if (resp.data.success == true) {
            this.text = resp.data.message.toString();
            this.color = "green";
            this.snackbar = true;
            this.getImagem();
          } else {
            this.text = resp.data.message.toString();
            this.color = "red";
            this.snackbar = true;
          }
        } catch (error) {
          this.text = error.toString();
          this.color = "red";
          this.snackbar = true;
        }
      });
    },
    deleteImagem() {
      var msg = confirm("Deseja mesmo remover está imagem?");
      if (msg) {
        return service.deleteImagem({ id: this.imagem.id }).then(resp => {
          try {
            if (resp.status == 200) {
              this.text = resp.data.message.toString();
              this.color = "green";
              this.snackbar = true;
              this.imagem = null;
            } else {
              this.text = resp.data.message.toString();
              this.color = "red";
              this.snackbar = true;
            }
          } catch (error) {
            this.text = error.toString();
            this.color = "red";
            this.snackbar = true;
          }
        });
      }
    }
  }
};
</script>
