<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <department-form :id="id" :item="item" @saved="onSaved"></department-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        disable-create-redirect
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["id", "description"],
      fields: [
        { source: "id", sortable: true },
        { source: "description", sortable: true },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ title, id, item }) {
      this.asideTitle = title;
      this.id = id;
             this.item = item != null ? item : item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
