<template>
  <div>
    <router-view />

    <!-- <v-snackbar right rounded="pill" top v-model="alerta" :color="color">
      * Nova Mensagem Recebida *
    </v-snackbar> -->
  </div>
</template>

<script>
import gql from "graphql-tag";

// eslint-disable-next-line no-unused-vars
const CURRENT_ALERT = gql`
  query {
    alerts {
      robo_id
      description
    }
  }
`;
export default {
  name: "App",
  data: function() {
    return {
      currentAlert: null,
      alerta: null,
      color: "red"
    };
  },
  apollo: {
    // Advanced query with parameters
    // The 'variables' method is watched by vue
    msgAlertas: {
      query: CURRENT_ALERT,
      deep: false,
      update(data) {
        if (data.alerts.length > 0) {
          this.alerta = true;
        }
        this.currentAlert = data.alerts;
        this.$root.$emit("ListAlertas", data.alerts);
        this.iniciaConsulta();
      },
      error() {
        console.log("Erro em buscar dados apollo");
      }
    }
  },
  methods: {
    iniciaConsulta() {
      this.$apollo.queries.msgAlertas.startPolling(5000);
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    this.$root.$on("getListAlertas", value => {
      this.$root.$emit("ListAlertas", this.currentAlert);
    });
  }
};
</script>
