<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-card-text>
      <va-text-input source="name" taggable></va-text-input>
      <va-text-input source="uf" taggable></va-text-input>
      <va-select-input
        source="country_id"
        chip
        :choices="comboCountry"
        taggable
      ></va-select-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      comboCountry: [],
    };
  },
  created() {
    this.getCountry();
  },
  methods: {
    getCountry() {
      service
        .getDados({
          endpoint: "default/Country",
          slugId: "id",
          slugDesc: "description",
        })
        .then((value) => {
          this.comboCountry = value;
        });
    },
  },
};
</script>
