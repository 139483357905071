<template>
  <va-edit-layout>
    <path-form :id="id" :title="title" :item="item"></path-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"]
};
</script>
