import service from "@/services/BaseService";
import axios from "axios";

export default {
  // Busca dados na api e retorna lista de rob
  data: {
    axios: axios
  },
  async getDados({ endpoint }) {
    try {
      var req = new service(endpoint);
      var response = await req.find();
      return response.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async postRoutRobot({ item, dns }) {
    try {
      var req = new service(`sendroute/${dns}`);
      var response = await req.create(item);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return "";
    }
  },
  async sendAction(action) {
    console.log(action)
    try {
      var req = new service("sendAction");
      var response = await req.create(action);
      return response.data;
    } catch (error) {
      console.log(error);
      return "";
    }
  },
  async getMapDepartment({ department_id }) {
    try {
      var getCombo = new service(`mapDepartment/${department_id}`);
      var result = await getCombo.find();
      return result.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
};
