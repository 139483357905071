<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <route-map-form :id="id" :item="item" @saved="onSaved"></route-map-form>
      </base-material-card>
    </va-aside-layout>

    <base-material-card title="Rotas">
      <template v-if="this.dns != null">
        <v-col>
          <v-row justify="start">
            <v-col sm="2">
              <!-- Utilizado para saber quantas vezes a rota será chamada -->
              <va-select-input
                placeholder="Numero de chamadas para a rota"
                v-model="qtd_requisicoes"
                label="Requisições"
                chip
                :choices="combo_qdte"
              ></va-select-input>
            </v-col>
            <v-col sm="4">
              <v-btn class="ma-2" color="green" @click="sendAction('start')"
                >Start</v-btn
              >
              <v-btn class="ma-2" color="red" @click="sendAction('stop')"
                >Stop</v-btn
              >
              <v-btn class="ma-2" color="blue" @click="sendAction('base')"
                >Voltar Base</v-btn
              >
            </v-col>
            <v-col>
              <CardDepartment
                v-bind:robo_id="this.$route.params.robo_id"
                v-bind:department_id="this.department_id"
              ></CardDepartment>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <div v-else>
        <label> Robo sem dns cadastrado!</label>
      </div>

      <va-list
        v-if="this.map_id != null && this.dns != null"
        resource="route_map"
        ref="list"
        disable-create-redirect
        :filters="filters"
        :include="includes"
        disable-global-search
        disable-export
        @action="onAction"
      >
        <va-data-table
          show-expand
          :fields="fields"
          disable-show
          disable-show-redirect
          disable-create-redirect
          disable-edit-redirect
          disable-clone
          disable-select
          @item-action="onAction"
        >
          <template v-slot:expanded-item="{ item }">
            <ListPath
              :route_id="item.id"
              :map_id="map_id"
              :dns="dns"
            ></ListPath>
          </template>
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import ListPath from "@/resources/route_map/path/List.vue";
import service from "@/resources/route_map/service";
import CardDepartment from "@/resources/dashboard/CardDepartment.vue";

export default {
  props: ["resource", "robo_id", "dns"],
  components: {
    ListPath,
    CardDepartment
  },
  data() {
    return {
      includes: [],
      filters: [],
      fields: [
        { source: "id", sortable: true },
        { source: "description", sortable: true },
        {
          source: "dateTimeIni",
          label: "Data Inicio",
          type: "date",
          sortable: true
        },
        {
          source: "specialFlow",
          label: "Fluxo Especial",
          type: "number",
          sortable: true
        },
        { source: "frequency", sortable: true },
        { source: "desc_map", label: "Mapa", sortable: true }
      ],
      qtd_requisicoes: "Ilimitado",
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false,
      combo_qdte: ["Ilimitado", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      department_id: null,
      map_id: null
    };
  },
  created() {
    this.item = {
      dateTimeIni: new Date()
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = " ";
      this.titleForm = title;
      this.id = id;
      if (item == null) {
        var _item = {};
        for (let i = 0; i < this.fields.length; i++) {
          _item[this.fields[i].source] = null;
        }
        _item["maps_id"] = this.map_id;
        _item["robot_id"] = parseInt(this.robo_id);
        _item["dateTimeIni"] = new Date();
        this.item = _item;
        console.log(this.item);
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    async sendAction(action) {
      try {
        var qtde = 0;
        if (this.qtd_requisicoes != "Ilimitado") {
          qtde = this.qtd_requisicoes;
        }
        var json = { action: action, qtde: qtde, dns: this.dns };
        var response = await service.sendAction(json);
        alert(response);
      } catch (error) {
        alert(error.toString());
      }
    },
    getMapDepartment() {
      try {
        service
          .getMapDepartment({
            department_id: this.department_id
          })
          .then(resp => {
            if (resp.length > 0) {
              this.map_id = resp[0].id;
              this.includes = [{ api: `route/${this.map_id}` }];
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.$root.$on("department_id", value => {
      this.asideTitle = "";
      this.map_id = null;
      this.department_id = value;
      this.getMapDepartment();
    });
  },
  beforeDestroy() {
    this.$root.$off("department_id", null);
  }
};
</script>
<style>
element.style {
  height: 300px;
}
</style>
