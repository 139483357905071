import service from "@/services/BaseService";
import axios from "axios";

export default {
  // Busca dados na api e retorna lista de rob
  data: {
    axios: axios
  },
  async getDados({ endpoint }) {
    try {
      var req = new service(endpoint);
      var response = await req.find();
      return response.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async getInfoRobo(dns) {
    try {
      var getValues = new service(`InfoRobo/${dns}`);
      return getValues.find().then(response => {
        return response.data;
      });
    } catch (error) {
      console.log(error);
      return "";
    }
  },
  async getDadosRobo(robot_id) {
    try {
      var getValues = new service(`default/Robot/${robot_id}`);
      return getValues.find().then(response => {
        return response.data.data;
      });
    } catch (error) {
      console.log(error);
      return "";
    }
  },
  getImagem({ map_id }) {
    let item = [];
    var getValues = new service(`ImageMap/Map/${map_id}/image`);
    return getValues.find().then(value => {
      value.data.data.forEach(element => {
        element.url = `https://robotc.admservice.com.br/api/v1/USERIMAGE/${element.id}/image`;
        //element.url = `http://localhost:8000/api/v1/USERIMAGE/${element.id}/image`;
        item.push(element);
      });
      return item;
    });
  },
  async getHistoryModel({ endpoint }) {
    try {
      var req = new service(endpoint);
      var response = await req.find();
      return response.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async getRobotDepartment({ endpoint, slugId, slugDesc }) {
    let items = [];
    try {
      var getCombo = new service(endpoint);
      await getCombo.find().then(value => {
        for (let i = 0; i < value.data.data.data.length; i++) {
          items.push({
            value: value.data.data.data[i][slugId],
            text: value.data.data.data[i][slugDesc]
          });
        }
      });
      return items;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async getMapDepartment({ department_id }) {
    try {
      var getCombo = new service(`mapDepartment/${department_id}`);
      var result = await getCombo.find();
      return result.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async getPointsMap({ map_id }) {
    try {
      var getCombo = new service(`point/${map_id}/params?_start=0&_end=100`);
      var result = await getCombo.find();
      return result.data.data.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }
};
