<template>
  <va-create-layout>
    <action-form :title="title" :item="item"></action-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>
