<template>
  <va-edit-layout>
    <robot-department-form
      :id="id"
      :title="title"
      :item="item"
    ></robot-department-form>
    <div v-if="this.item != null">
      <listRobotDepartment :robo_id="id"></listRobotDepartment>
    </div>
  </va-edit-layout>
</template>

<script>
import listRobotDepartment from "@/resources/robot_department/List.vue";
export default {
  props: ["id", "title", "item"],
  components: { listRobotDepartment }
};
</script>
