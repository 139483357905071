var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.infoRobo.length > 0)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"lg":"3"}},[_c('base-material-card',{attrs:{"color":"info","icon":"mdi-ev-station","title":"Porcentagem Bateria"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v(_vm._s(_vm.date_att))])]},proxy:true}],null,false,3417884838)},[_c('v-progress-linear',{attrs:{"color":"light-blue","height":"20","value":_vm.infoRobo[2].split(':')[1],"striped":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(value)+"%")])]}}],null,false,785829883)})],1)],1),_c('v-col',{attrs:{"lg":"3"}},[_c('base-material-card',{attrs:{"color":"green","icon":"mdi-memory","title":"Temperatura CPU"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v(_vm._s(_vm.date_att))])]},proxy:true}],null,false,3417884838)},[_c('v-progress-linear',{attrs:{"color":"light-blue","height":"20","value":_vm.infoRobo[9].split(':')[1],"striped":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"V")])]}}],null,false,3313895956)})],1)],1),_c('v-col',{attrs:{"lg":"3"}},[_c('base-material-card',{attrs:{"color":"green","icon":"mdi-memory","title":"Uso CPU"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v(_vm._s(_vm.date_att))])]},proxy:true}],null,false,3417884838)},[_c('v-progress-linear',{attrs:{"color":"light-blue","height":"20","value":_vm.infoRobo[10].split(':')[1],"striped":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,false,3002676263)})],1)],1),_c('v-col',{attrs:{"lg":"3"}},[_c('base-material-card',{attrs:{"color":"green","icon":"mdi-play-speed","title":"Tempo de Rodagem"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v(_vm._s(_vm.date_att))])]},proxy:true}],null,false,3417884838)},[_c('v-progress-linear',{attrs:{"color":"transparent","height":"20","value":"100","striped":""}},[[_c('strong',[_vm._v("3h00min")])]],2)],1)],1),_c('v-col',{attrs:{"lg":"3"}},[_c('base-material-card',{attrs:{"color":"red","icon":"mdi-stop","title":"Total de Paradas"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-clock-outline ")]),_c('span',{staticClass:"caption grey--text font-weight-light"},[_vm._v(_vm._s(_vm.date_att))])]},proxy:true}],null,false,3417884838)},[_c('v-progress-linear',{attrs:{"color":"transparent","height":"20","value":"225","striped":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(value))])]}}],null,false,3055762101)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }