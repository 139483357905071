<template>
  <va-edit-layout>
    <contact-form :id="id" :title="title" :item="item"></contact-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  created(){
    (this.id);
    (this.item)
  }
};
</script>
