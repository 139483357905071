import axios from "axios";
/** @type {BaseService} */
export default class BaseService {
  constructor(api) {
    this.api = `https://robotc.admservice.com.br/api/v1/${api}`;
    //this.api = `http://localhost:8000/api/v1/${api}`;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    // this.axios.defaults.headers.common[
    //   "Authorization"
    //   // eslint-disable-next-line no-undef
    // ] = localStorage.token;
    this.axios = axios;
  }

  /**
   * Listar todos os itens
   */
  find(obj) {
    //
    if (obj) {
      const query = Object.keys(obj)
        .map(k => `${k}=${encodeURIComponent(obj[k])}`)
        .join("&");
      return this.axios.get(`${this.api}?${query}`);
    } else {
      return this.axios.get(`${this.api}`);
    }
  }

  /**
   * Custom Post
   */
  post(url, obj) {
    if (url != undefined) {
      return this.axios.post(`${this.api}/${url}`, obj);
    }
    return this.axios.post(`${this.api}`, obj);
  }

  /**
   * Listar todos os itens
   */
  params(obj) {
    const query = Object.keys(obj)
      .map(() => "${k}=${encodeURIComponent(obj[k])}")
      .join("&");
    return this.axios.get(`${this.api}/param?${query}`);
  }

  /**
   * Listar todos os itens
   */
  search(obj) {
    const query = Object.keys(obj)
      .map(() => "${k}=${encodeURIComponent(obj[k])}")
      .join("&");
    return this.axios.get(`${this.api}/search?${query}`);
  }

  /**
   *  Selecionar o equivalente ao id passado
   * @param {*} id
   */
  findOne(id) {
    return this.axios.get(`${this.api}/${id}`);
  }

  /**
   *  Inserir um novo item
   * @param {*} data
   */
  create(data) {
    return this.axios.post(`${this.api}`, data);
  }

  /**
   *  Atualizar um item
   * @param {*} data
   */
  update(id, data) {
    return this.axios.put(`${this.api}/${id}`, data);
  }

  /**
   * Remover um item
   * @param {*} id
   */
  delete(id) {
    return this.axios.delete(`${this.api}/${id}`);
  }
    /**
   *  Inserir um novo item type form-data
   * @param {*} data
   */
  createFormData(data) {
    const config = {
      headers: { "content-type": "multipart/form-data" }
    };

    return this.axios.post(`${this.api}`, data, config);
  }
}
