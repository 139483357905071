<template>
  <va-form
    resource="robot_department"
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
  >
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-select-input
        source="department_id"
        chip
        :choices="comboDepartment"
      ></va-select-input>
      <va-select-input
        source="robot_id"
        chip
        :choices="comboRobot"
      ></va-select-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      comboDepartment: [],
      comboRobot: []
    };
  },
  created() {
    this.getDepartment();
    this.getRobots();
  },
  methods: {
    getDepartment() {
      service
        .getDados({
          endpoint: "default/Department",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboDepartment = value;
        });
    },
    getRobots() {
      service
        .getDados({
          endpoint: "default/Robot",
          slugId: "id",
          slugDesc: "name"
        })
        .then(value => {
          this.comboRobot = value;
        });
    }
  }
};
</script>
