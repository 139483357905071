export default [
  {
    name: "default",
    icon: "mdi-account",
    label: "/Point",
    api: "aaa",
    routes: ["list"],
    translatable: true
  },
  {
    name: "usser",
    icon: "mdi-account",
    label: "User",
    routes: ["list"],
    translatable: true
  },
  {
    name: "user",
    api: "default/User",
    icon: "mdi-account",
    label: "User",
    routes: ["list"],
    translatable: true
  },
  {
    name: "country",
    api: "default/Country",
    icon: "mdi-flag",
    label: "Países",
    routes: ["list"],
    translatable: true
  },
  {
    name: "person",
    api: "default/Person",
    icon: "mdi-account-group",
    label: "Pessoas"
  },
  {
    name: "department",
    api: "default/Department",
    icon: "mdi-ballot",
    label: "Departamento",
    translatable: true
  },
  {
    name: "role",
    api: "default/Role",
    icon: "mdi-account-tie",
    label: "Função",
    translatable: true
  },
  {
    name: "state",
    api: "default/State",
    icon: "mdi-flag-variant",
    label: "Estado"
  },
  {
    name: "map",
    api: "default/Map",
    icon: "mdi-tag",
    label: "Map",
    actions: undefined
  },
  {
    name: "contact",
    api: "default/Contact",
    icon: "mdi-phone",
    label: "Contato"
  },
  {
    name: "form",
    api: "default/Form",
    icon: "mdi-form-select",
    label: "Form"
  },
  {
    name: "authorization",
    api: "default/Authorization",
    icon: "mdi-tag",
    label: "Autorização"
  },
  {
    name: "robot",
    api: "default/Robot",
    icon: "mdi-robot",
    label: "Robo",
    translatable: true
  },
  {
    name: "model",
    api: "default/Model",
    icon: "mdi-palette-swatch-outline",
    label: "Model"
  },
  {
    name: "route",
    api: "default/Route",
    icon: "mdi-router",
    label: "Route"
  },
  {
    name: "path",
    api: "/path",
    icon: "mdi-map-marker-path",
    label: "Path"
  },
  {
    name: "point",
    api: "default/Point",
    icon: "mdi-access-point",
    label: "Point"
  },
  {
    name: "pointsmap",
    api: "default/Point",
    icon: "mdi-access-point",
    label: "Point"
  },
  {
    name: "action",
    api: "default/Action",
    icon: "mdi-gesture-tap-hold",
    label: "Action"
  },
  {
    name: "route_map",
    api: "default/Route",
    icon: "mdi-router",
    label: "Route"
  },
  {
    name: "robot_department",
    api: "default/RobotDepartment",
    icon: "mdi-robot",
    label: "Robo Departamento",
    translatable: true
  },
  {
    name: "topics",
    icon: "mdi-robot",
    label: "Topicos",
  }
];
