/* eslint-disable no-undef */
import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";
import Vuetify from 'vuetify/lib'
Vue.use(Vuetify);

import "vuetify-admin/src/loader";
import sanctumAuthProvider from "@/providers/auth/sanctum";
import jsonServerDataProvider from "@/providers/json";
import pt from "@/locales/pt-actions.json";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";

import ApolloClient from "apollo-boost";
import VueApollo from "vue-apollo";
Vue.use(VueApollo);

import moment from "moment";
moment.locale("pt-br");

const apolloProvider = new VueApollo({
  defaultClient: new ApolloClient({
    uri: "http://localhost:4001"
  })
});

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
//const baseURL = "http://localhost:8000/api/v1/";
const baseURL = "https://robotc.admservice.com.br/api/v1/";

const http = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.token
  }
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  apolloProvider,
  router,
  store,
  i18n,
  title: "33 Robotics",
  routes,
  locales: {
    pt
  },
  translations: ["pt"],
  dataProvider: jsonServerDataProvider(http),
  authProvider: sanctumAuthProvider(http, {
    routes: {
      login: "/login",
      user: "/login"
    }
  }),
  resources,
  http,
  options: {
    dateFormat: "long"
  }
});
