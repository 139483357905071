<template>
  <va-edit-layout>
    <authorization-form
      :id="id"
      :title="title"
      :item="item"
    ></authorization-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"]
};
</script>
