import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/resources/dashboard/Dashboard";
import Error from "@/views/Error";
import ErrorRoute from "@/views/ErrorRoute";
import i18n from "@/i18n";
import DetailsRobo from "@/resources/dashboard/DetailsRobo";

export default {
  path: "",
  component: AdminLayout,
  meta: {
    title: i18n.t("routes.home")
  },
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        title: i18n.t("routes.dashboard")
      }
    },
    {
      path: "/details/:robo_id",
      name: "details",
      component: DetailsRobo,
      meta: {
        title: "Detalhes Robo"
      }
    },
    {
      path: "/error",
      component: ErrorRoute,
      name: "Error",
      props: true
    },
    {
      path: "*",
      component: Error,
      meta: {
        title: i18n.t("routes.not_found")
      },
      props: true
    }
  ]
};
