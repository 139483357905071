<template>
  <base-material-card :icon="iconCamera" title="Informações do Robo">
    <v-card-text>
      <v-row justify="end">
        <v-btn class="ml-2" outlined small @click="getInformaçõesRobo()">
          Atualizar
          <v-icon right> mdi-refresh </v-icon>
        </v-btn>
      </v-row>
      <v-row v-for="(item, index) in this.infoRobo" :key="index">
        {{ item.campo }} : {{ item.valor }}
      </v-row>
    </v-card-text>
  </base-material-card>
</template>

<script>
import service from "@/resources/dashboard/service";

export default {
  props: ["dns"],
  data() {
    return {
      iconCamera: "mdi-camera",
      infoRobo: ""
    };
  },
  created() {
    this.getInformaçõesRobo();
  },
  methods: {
    getInformaçõesRobo() {
      service.getInfoRobo(this.dns).then(value => {
        const array = Object.entries(value).map(arr => ({
          campo: arr[0],
          valor: arr[1]
        }));
        this.infoRobo = array;
      });
    }
  }
};
</script>
