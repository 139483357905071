<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-text-input source="name"></va-text-input>
      <va-text-input source="dns"></va-text-input>
      <va-number-input source="inspectionHours"></va-number-input>
      <va-number-input source="horimetre"></va-number-input>
      <va-select-input
        source="modelo_id"
        chip
        :choices="comboModel"
      ></va-select-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      comboModel: []
    };
  },
  created() {
    this.getModel();
  },
  methods: {
    getModel() {
      service
        .getDados({
          endpoint: "default/Model",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboModel = value;
        });
    }
  }
};
</script>
