<template>
  <div>
    <!-- <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
       
      </base-material-card> -->

    <va-form
      resource="route_map"
      :id="id"
      :item="item"
      disable-redirect
      @saved="$emit('saved')"
    >
      <v-col sm="12" lg="12">
        <v-card-text>
          <va-text-input source="description" taggable></va-text-input>
          <va-date-input source="dateTimeIni" taggable></va-date-input>
          <va-select-input
            source="maps_id"
            chip
            :choices="comboMap"
            taggable
          ></va-select-input>
          <va-number-input source="specialFlow" taggable></va-number-input>
          <va-text-input source="frequency" taggable></va-text-input>
        </v-card-text>
        <va-save-button label="Salvar"></va-save-button>
      </v-col>
    </va-form>
  </div>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        active: false
      },
      comboMap: [],
      comboRobot: []
    };
  },
  created() {
    this.getMap();
  },
  methods: {
    getMap() {
      service
        .getDados({
          endpoint: "default/Map",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboMap = value;
        });
    }
  }
};
</script>
