<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <FormPath
          :route_id="route_id"
          :id="id"
          :item="item"
          @saved="onSaved"
        ></FormPath>
      </base-material-card>
    </va-aside-layout>
    <base-material-card :title="title">
      <va-list
        ref="list"
        resource="path"
        :include="includes"
        :filters="filters"
        disable-create-redirect
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import FormPath from "@/resources/route/path/Form.vue";

export default {
  props: ["route_id"],
  components: {
    FormPath
  },
  data() {
    return {
      includes: [{ api: `path/${this.route_id}` }],
      filters: [
        "id",
        "sequence",
        { source: "route_id", type: "select" },
        { source: "pointIni_id", type: "select" },
        { source: "action_id", type: "select" }
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "sequence", sortable: true },
        { source: "desc_route", label: "Rota", type: "select", sortable: true },
        { source: "pointIni_id", type: "select", sortable: true },
        { source: "action_id", type: "select", sortable: true }
      ],
      asideTitle: null,
      titleForm: null,
      title: "Caminhos",
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = " ";
      this.titleForm = title;
      this.id = id;

      if (item == null) {
        var _item = {};
        for (let i = 0; i < this.fields.length; i++) {
          _item[this.fields[i].source] = null;
        }
        _item["route_id"] = this.route_id;
        this.item = _item;
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    }
  }
};
</script>
