<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-card-text>
      <va-text-input source="name" taggable></va-text-input>
      <va-number-input source="cnpjCpf"></va-number-input>
      <va-number-input source="cep"></va-number-input>
      <va-text-input source="address" taggable></va-text-input>
      <va-number-input source="number"></va-number-input>
      <va-text-input source="complement" taggable></va-text-input>
      <va-text-input source="district" taggable></va-text-input>
      <va-select-input
        source="department_id"
        chip
        :choices="comboDepartment"
      ></va-select-input>
      <va-select-input
        source="role_id"
        chip
        :choices="comboRole"
      ></va-select-input>
      <va-select-input
        source="state_id"
        chip
        :choices="comboState"
      ></va-select-input>
      <va-text-input source="city"></va-text-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      comboDepartment: [],
      comboRole: [],
      comboState: []
    };
  },
  created() {
    this.getDepartments();
    this.getRoles();
    this.getState();
  },
  methods: {
    getDepartments() {
      service
        .getDados({
          endpoint: "default/Department",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboDepartment = value;
        });
    },
    getRoles() {
      service
        .getDados({
          endpoint: "default/Role",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboRole = value;
        });
    },
    getState() {
      service
        .getDados({
          endpoint: "default/State",
          slugId: "uf",
          slugDesc: "name"
        })
        .then(value => {
          this.comboState = value;
        });
    }
  }
};
</script>
