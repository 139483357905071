<template>
  <!-- ===================== DADOS FORM ========================= -->
  <va-form :id="id" :item="item" @saved="submit">
    <base-material-card>
      <template v-slot:heading>
        <div class="display-2">
          {{ title }}
        </div>
      </template>
      <v-card-text>
        <va-text-input source="description" taggable></va-text-input>
        <va-select-input
          source="department_id"
          chip
          :choices="comboDepartment"
        ></va-select-input>
        <!-- ===================== DADOS IMAGEM ========================= -->
        <div v-if="this.imagem == null && this.item != null">
          <v-file-input
            show-size
            v-on:change="onImageChange"
            label="Imagem Mapa"
            filled
            prepend-icon="mdi-camera"
          ></v-file-input>
        </div>
        <va-save-button label="Salvar"></va-save-button>
      </v-card-text>
      <va-form v-if="this.imagem != null">
        <v-col class="text-right">
          <v-btn color="red" class="me-2 white--text" @click="deleteImagem">
            Excluir Imagem
          </v-btn>
        </v-col>
        <div>
          <v-row justify="center">
            <v-col sm="12"> <v-img :src="`${imagem['url']}`"></v-img> </v-col
          ></v-row>
        </div>
      </va-form>
    </base-material-card>

    <v-snackbar v-model="snackbar" :color="color">
      {{ text }}
    </v-snackbar>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";

export default {
  props: ["id", "title", "item"],

  data() {
    return {
      model: {
        active: false
      },
      comboDepartment: [],
      img: null,
      imagem: null,
      snackbar: false,
      text: "",
      color: "gray"
    };
  },
  created() {
    this.getDepartment();
    this.getImagem();
  },
  methods: {
    getDepartment() {
      service
        .getDados({
          endpoint: "default/Department",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboDepartment = value;
        });
    },
    getImagem() {
      try {
        service
          .getImagem({
            map_id: this.item.id
          })
          .then(resp => {
            if (resp.length > 0) {
              this.imagem = resp[0];
            }
          });
      } catch (e) {
        this.null = null;
      }
    },
    onImageChange(file) {
      let formData = new FormData();
      formData.append("image", file);
      formData.append("table", "Map");
      formData.append("table_id", this.item.id);
      formData.append("user_id", 1);
      this.img = formData;
    },
    submit() {
      if (this.img != null) {
        return service.postImagem({ item: this.img }).then(resp => {
          try {
            if (resp.data.success == true) {
              this.getImagem();
            } else {
              this.text = resp.data.message.toString();
              this.color = "red";
              this.snackbar = true;
            }
          } catch (error) {
            this.text = error.toString();
            this.color = "red";
            this.snackbar = true;
          }
        });
      }
    },
    deleteImagem() {
      var msg = confirm("Deseja mesmo remover está imagem?");
      if (msg) {
        return service.deleteImagem({ id: this.imagem.id }).then(resp => {
          try {
            if (resp.status == 200) {
              this.text = resp.data.message.toString();
              this.color = "green";
              this.snackbar = true;
              this.imagem = null;
            } else {
              this.text = resp.data.message.toString();
              this.color = "red";
              this.snackbar = true;
            }
          } catch (error) {
            this.text = error.toString();
            this.color = "red";
            this.snackbar = true;
          }
        });
      }
    }
  }
};
</script>
