<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <person-form :id="id" :item="item" @saved="onSaved"></person-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        :filters="filters"
        disable-create-redirect
        :include="filters"
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        "id",
        "name",
        "cnpjCpf",
        { source: "cep", type: "number" },
        "address",
        { source: "number", type: "number" },
        "complement",
        "district",
        { source: "department_id", type: "select" },
        { source: "role_id", type: "select" },
        { source: "state_id", type: "select" },
        "city",
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "name", sortable: true },
        { source: "cnpjCpf", sortable: true },
        { source: "cep", type: "number", sortable: true },
        { source: "address", sortable: true },
        { source: "number", type: "number", sortable: true },
        { source: "complement", sortable: true },
        { source: "district", sortable: true },
        { source: "department_id", type: "select", sortable: true },
        { source: "role_id", type: "select", sortable: true },
        { source: "state_id", type: "select", sortable: true },
        { source: "city", sortable: true },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ title, id, item }) {
      this.asideTitle = title;
      this.id = id;
             this.item = item != null ? item : item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
