<template>
  <va-form
    resource="point"
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
  >
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-text-input source="description" taggable></va-text-input>
      <va-number-input source="coordinateX" taggable></va-number-input>
      <va-number-input source="coordinateY" taggable></va-number-input>
      <va-number-input source="coordinateZ" taggable></va-number-input>
      <va-number-input source="coordinateW" taggable></va-number-input>
      <va-select-input
        source="maps_id"
        chip
        :choices="comboMap"
        taggable
      ></va-select-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["resource", "id", "title", "item"],
  data() {
    return {
      comboMap: []
    };
  },
  created() {
    this.getMap();
  },
  methods: {
    getMap() {
      service
        .getDados({
          endpoint: "default/Map",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboMap = value;
        });
    }
  }
};
</script>
