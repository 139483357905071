<template>
  <div>
    <v-row>
      <v-col v-for="robo in robos" :key="robo.id">
        <v-card elevation="8" :color="robo.color">
          <v-card-title>
            <v-icon large left color="rgb(144, 10, 10)"> mdi-robot </v-icon>
            <span class="subheading mr-4">{{ robo.name }}</span>
          </v-card-title>
          <v-card-text>
            <v-list-item class="grow">
              <v-col>
                <v-row>
                  <v-list-item-content>
                    <v-list-item-title>Status</v-list-item-title>
                  </v-list-item-content>
                  <v-row align="center" justify="end">
                    <span
                      class="title font-weight-light mr-2"
                      style="color: green"
                      >Em transito</span
                    >
                    <v-icon class="mr-1" color="green"> mdi-circle </v-icon>
                  </v-row>
                </v-row>
                <v-row v-if="robo.alerts.length > 0">
                  <v-list-item-content>
                    <span
                      v-for="robo in robo.alerts"
                      :key="robo.id"
                      class="title font-weight-light mr-2"
                      style="color: red"
                      >{{ robo.description.replaceAll(",", "\n") }}</span
                    >
                  </v-list-item-content>
                  <v-row align="center" justify="end">
                    <v-icon class="mr-1" color="red" size="40px">
                      mdi-alert
                    </v-icon>
                  </v-row>
                </v-row>
              </v-col>
            </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-btn class="ml-2" outlined small>
              Informações
              <v-icon right> mdi-information-variant </v-icon>
            </v-btn>
            <v-btn @click="routerGo(robo.id)" class="ml-2" outlined small>
              Outras Opções
              <v-icon right> mdi-view-list </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import service from "@/resources/dashboard/service";

export default {
  // Apollo-specific options
  watch: {
    // robos(value) {
    //   if (value.length == 0) {
    //     this.robos.forEach(element => {
    //       element.color = "white";
    //       element.alerts = [];
    //     });
    //   }
    //   this.robos.forEach(element => {
    //     element.alerts = [];
    //     element.color = "white";
    //     if (this.currentAlert != null) {
    //       this.currentAlert.forEach(value => {
    //         if (value.robo_id == element.id) {
    //           element.color = "yellow";
    //           element.alerts.push(value);
    //         }
    //       });
    //     }
    //   });
    // }
  },
  data: function() {
    return {
      currentAlert: null,
      robos: []
    };
  },
  created() {
    this.getRobos();
  },

  methods: {
    getRobos() {
      service
        .getDados({
          endpoint: "default/Robot"
        })
        .then(value => {
          value.forEach(element => {
            element.alerts = [];
            element.color = "white";
          });
          this.robos = value;
          this.$root.$emit("getListAlertas", []);
        });
    },
    routerGo(robo_id) {
      this.$router.push({ path: `/details/${robo_id}` });
    }
  },
  mounted() {
    this.$root.$on("ListAlertas", value => {
      if (value != null) {
        if (value.length == 0) {
          this.robos.forEach(element => {
            element.color = "white";
            element.alerts = [];
          });
        }
        this.robos.forEach(element => {
          element.alerts = [];
          element.color = "white";
          value.forEach(value => {
            if (value.robo_id == element.id) {
              element.color = "yellow";
              element.alerts.push(value);
            }
          });
        });
      }
    });
  }
};
</script>
