<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <FormPath
          :id="id"
          :item="item"
          :map_id="map_id"
          @saved="onSaved"
        ></FormPath>
      </base-material-card>
    </va-aside-layout>
    <base-material-card :title="title">
      <va-list
        ref="list"
        resource="path"
        :include="includes"
        disable-create-redirect
        @action="onAction"
        disable-export
        disable-global-search
      >
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-select
          disable-edit
          disable-edit-redirect
          disable-show
          disable-show-redirect
          @item-action="onAction"
        >
        </va-data-table>
        <template v-slot:actions>
          <v-btn class="ma-2 white--text" color="primary" @click="sendPoints">
            Enviar Rotas Robo
            <v-icon right dark> mdi-send </v-icon>
          </v-btn>
        </template>
      </va-list>
    </base-material-card>
  </div>
</template>
<script>
import FormPath from "@/resources/route_map/path/Form.vue";
import service from "@/resources/route_map/service";

export default {
  props: ["route_id", "map_id", "dns"],
  components: {
    FormPath,
  },
  data() {
    return {
      includes: [{ api: `path/${this.route_id}` }],
      fields: [
        // { source: "id", sortable: true },
        { source: "sequence", sortable: true },
        { source: "desc_point", label: "Ponto", sortable: true },
        { source: "desc_route", label: "Rota", sortable: true },
        { source: "desc_action", label: "Ação", sortable: true },
      ],
      asideTitle: null,
      titleForm: null,
      title: "Caminhos",
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async sendRoute(listRobot) {
      try {
        await service.postRoutRobot({ item: listRobot, dns: this.dns });
        alert("Rotas Enviado com Sucesso!");
      } catch (error) {
        alert(error);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = " ";
      this.titleForm = title;
      this.id = id;
      if (item == null) {
        var _item = {};
        for (let i = 0; i < this.fields.length; i++) {
          _item[this.fields[i].source] = null;
        }
        _item["route_id"] = this.route_id;
        this.item = _item;
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    sendPoints() {
      try {
        if (this.$refs.list.listState.items.length > 0) {
          var objRobot = {};
          var list = this.$refs.list.listState.items;
          const steps = [];

          for (let i = 1; i < list.length; i++) {
            var step = {
              name: list[i].desc_point,
              x: list[i].x,
              y: list[i].y,
              z: list[i].z,
              w: list[i].w,
              action: list[i].desc_action,
              sequence: list[i].sequence,
            };
            steps.push(step);
          }
          objRobot = {
            initial: {
              name: list[0].desc_point,
              x: list[0].x,
              y: list[0].y,
              z: list[0].z,
              w: list[0].w,
              action: list[0].desc_action,
              sequence: list[0].sequence,
            },
            steps,
          };
          this.sendRoute(objRobot);
        } else {
          alert("Não possui caminhos para ser enviado para o robo.");
        }
      } catch (error) {
        print(error);
      }
    },
  },
};
</script>

