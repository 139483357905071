<template>
  <base-material-card :title="title">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <v-row>
        <v-col lg="3">
          <v-btn outlined small v-if="zoom" @click="zoom = false" color="blue">
            Zoom
            <v-icon right> mdi-image </v-icon>
          </v-btn>
          <v-btn outlined small v-else @click="zoom = true">
            Zoom
            <v-icon right> mdi-image </v-icon>
          </v-btn>
        </v-col>
        <v-col lg="2">
          <v-btn outlined small @click="setPoints">
            Mostrar Pontos
            <v-icon right> mdi-image </v-icon>
          </v-btn>
        </v-col>
        <v-col lg="1">
          <v-btn outlined small @click="cleanPoints">
            Limpar Pontos
            <v-icon right> mdi-image </v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <CardDepartment
            v-bind:robo_id="this.$route.params.robo_id"
            v-bind:department_id="this.department_id"
          ></CardDepartment>
        </v-col>
      </v-row>

      <!-- ===================== DADOS IMAGEM ========================= -->

      <v-row justify="space-around">
        <va-form v-if="this.imagem != null">
          <div @click="getCoordinates" v-if="zoom == true">
            <image-zoom
              :regular="`${imagem['url']}`"
              img-class="size-imagem"
              :zoom-amount="3"
              :showMessage="false"
              alt="sky"
            >
            </image-zoom>
          </div>
          <div v-else>
            <v-img
              class="size-imagem"
              id="image"
              :src="`${imagem['url']}`"
              @click="getCoordinates"
            ></v-img>
          </div>
        </va-form>
        <div v-else>
          <label>Selecione um departamento que possua mapa cadastrado.</label>
        </div>

        <v-col v-if="this.map_id != null" lg="5">
          <ListPoints :mapa_id="this.map_id"></ListPoints
        ></v-col>
      </v-row>
    </v-card-text>
  </base-material-card>
</template>

<script>
import service from "@/resources/dashboard/service";
import ListPoints from "@/resources/dashboard/Map/points/List.vue";
import imageZoom from "vue-image-zoomer";
import CardDepartment from "@/resources/dashboard/CardDepartment.vue";

//import ROSLIB from "roslib";

export default {
  props: ["item"],
  components: { ListPoints, imageZoom, CardDepartment },

  data() {
    return {
      imagem: null,
      ros: null,
      title: "Mapa Robo",
      zoom: false,
      msgX: null,
      msgY: null,
      department_id: null,
      map_id: null,
      count_points: 0
    };
  },
  methods: {
    getMapDepartment() {
      try {
        service
          .getMapDepartment({
            department_id: this.department_id
          })
          .then(resp => {
            if (resp.length > 0) {
              this.map_id = resp[0].id;
              this.getImagem();
              this.setPoints();
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    getImagem() {
      try {
        service
          .getImagem({
            map_id: this.map_id
          })
          .then(resp => {
            if (resp.length > 0) {
              this.imagem = resp[0];
            }
          });
      } catch (e) {
        this.null = null;
      }
    },
    getCoordinates(event) {
      this.$root.$emit("newPointMap", event);
    },
    setPoints() {
      try {
        this.cleanPoints();

        service
          .getPointsMap({
            map_id: this.map_id
          })
          .then(resp => {
            if (resp.length > 0) {
              this.count_points = resp.length;
              resp.forEach(element => {
                this.setPoint(
                  element.coordinateX,
                  element.coordinateY,
                  element.description
                );
              });
            }
          });
      } catch (e) {
        this.null = null;
      }
    },
    cleanPoints() {
      for (let i = 0; i < this.count_points; i++) {
        var div = document.querySelector("#image .ponto");
        if (div !== null) div.outerHTML = "";
      }
    },
    setPoint(x, y, label) {
      var div = document.getElementById("image");
      var ponto = document.createElement("label");
      ponto.textContent = label;
      ponto.className = "mdi mdi-map-marker ponto ";
      ponto.style.cssText =
        "top: " +
        (parseInt(y) - 20) +
        "px; left: " +
        (parseInt(x) - 10) +
        "px;" +
        "font-size:20px;" +
        "color:blue;";

      div.appendChild(ponto); // crio o ponto
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"
    );
    recaptchaScript.setAttribute(
      "src",
      "https://static.robotwebtools.org/EventEmitter2/current/eventemitter2.min.js"
    );
    recaptchaScript.setAttribute(
      "src",
      "https://static.robotwebtools.org/roslibjs/current/roslib.min.js"
    );
    document.head.appendChild(recaptchaScript);
    this.$root.$on("department_id", value => {
      this.cleanPoints();

      this.map_id = null;
      this.department_id = value;
      this.getMapDepartment();
    });
    this.$root.$on("Points_Map", value => {
      console.log(value.items);
    });
    this.$root.$on("atualizaPointsMap", () => {
      this.setPoints();
    });
  },
  beforeDestroy() {
    this.$root.$off("department_id", null);
  }
};
</script>
<style>
.size-imagem {
  width: 800px;
  height: 100%;
}

.ponto {
  height: 15px;
  border-radius: 50%;
  position: absolute;
}
</style>
