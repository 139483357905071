<template v-slot:default="infoRobo">
  <div v-if="infoRobo.length > 0">
    <v-row>
      <!-- BATERIA VOLTAGEM-->
      <!-- <v-col lg="3">
        <base-material-card
          color="info"
          icon="mdi-ev-station"
          title="Voltagem Bateria"
        >
          <v-progress-linear
            color="light-blue"
            height="20"
            :value="infoRobo[1].split(':')[1]"
            striped
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}V</strong>
            </template></v-progress-linear
          >
          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span class="caption grey--text font-weight-light">{{
              date_att
            }}</span>
          </template>
        </base-material-card> 
      </v-col> -->

      <!-- BATERIA PORCENTAGEM -->
      <v-col lg="3">
        <base-material-card
          color="info"
          icon="mdi-ev-station"
          title="Porcentagem Bateria"
        >
          <v-progress-linear
            color="light-blue"
            height="20"
            :value="infoRobo[2].split(':')[1]"
            striped
          >
            <template v-slot:default="{ value }">
              <strong>{{ value }}%</strong>
            </template>
          </v-progress-linear>
          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span class="caption grey--text font-weight-light">{{
              date_att
            }}</span>
          </template>
        </base-material-card>
      </v-col>

      <!-- TEMPERATURA CPU -->
      <v-col lg="3">
        <base-material-card
          color="green"
          icon="mdi-memory"
          title="Temperatura CPU"
        >
          <v-progress-linear
            color="light-blue"
            height="20"
            :value="infoRobo[9].split(':')[1]"
            striped
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}V</strong>
            </template></v-progress-linear
          >
          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span class="caption grey--text font-weight-light">{{
              date_att
            }}</span>
          </template>
        </base-material-card>
      </v-col>

      <!-- USO DA CPU -->
      <v-col lg="3">
        <base-material-card color="green" icon="mdi-memory" title="Uso CPU">
          <v-progress-linear
            color="light-blue"
            height="20"
            :value="infoRobo[10].split(':')[1]"
            striped
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template></v-progress-linear
          >
          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span class="caption grey--text font-weight-light">{{
              date_att
            }}</span>
          </template>
        </base-material-card>
      </v-col>
      <!-- INFORMAÇÕES ESTATICAS -->
      <!-- Tempo de Rodagem -->
      <v-col lg="3">
        <base-material-card
          color="green"
          icon="mdi-play-speed"
          title="Tempo de Rodagem"
        >
          <v-progress-linear
            color="transparent"
            height="20"
            value="100"
            striped
          >
            <template>
              <strong>3h00min</strong>
            </template></v-progress-linear
          >
          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span class="caption grey--text font-weight-light">{{
              date_att
            }}</span>
          </template>
        </base-material-card>
      </v-col>
      <!-- Total de Paradas -->
      <v-col lg="3">
        <base-material-card
          color="red"
          icon="mdi-stop"
          title="Total de Paradas"
        >
          <v-progress-linear
            color="transparent"
            height="20"
            value="225"
            striped
          >
            <template v-slot:default="{ value }">
              <strong>{{ value }}</strong>
            </template></v-progress-linear
          >
          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
            <span class="caption grey--text font-weight-light">{{
              date_att
            }}</span>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import service from "@/resources/dashboard/service";
import moment from "moment";

export default {
  props: ["robo_id"],
  data() {
    return {
      infoRobo: [],
      date_att: "",
      color_cards: "#ead3d6"
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    getHistory() {
      service
        .getHistoryModel({
          endpoint: `HistoryMoviment/${this.robo_id}`
        })
        .then(value => {
          if (value.length > 0) {
            if (value[0].value != null) {
              var list = value[0].value.split(",");
              this.date_att = moment(
                value[0].created_at,
                "YYYY-MM-DD H:mm"
              ).format("LLL");

              var pctBateria = 100 - (list[1].split(":")[1] * 100) / 25;
              list[2] = `Porcentagem Bateria:${pctBateria}`;
              this.infoRobo = list;
            }
          }
        });
    }
  }
};
</script>
