<template>
  <div>
    <v-col>
      <v-row lg="12">
        <v-list-item class="grow">
          <v-list-item-content>
            <v-list-item-title>Controles</v-list-item-title>
          </v-list-item-content>
          <v-card elevation="0">
            <!-- BTN MAPA -->
            <v-btn
              class="ml-2"
              outlined
              small
              v-if="statusMap == true"
              color="blue"
              @click="setItem('map', false)"
            >
              Mapa
              <v-icon right> mdi-map </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              outlined
              small
              v-else
              @click="setItem('map', true)"
            >
              Mapa
              <v-icon right> mdi-map </v-icon>
            </v-btn>
            <!-- BTN CAMERA -->
            <v-btn
              class="ml-2"
              outlined
              small
              v-if="statusCam == true"
              color="blue"
              @click="setItem('cam', false)"
            >
              Câmera do Robô
              <v-icon right> mdi-camera </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              outlined
              small
              v-else
              @click="setItem('cam', true)"
            >
              Câmera do Robô
              <v-icon right> mdi-camera </v-icon>
            </v-btn>
            <!-- BTN INFORMAÇÕES -->
            <v-btn
              class="ml-2"
              outlined
              small
              v-if="statusInfo == true"
              color="blue"
              @click="setItem('info', false)"
            >
              Informações
              <v-icon right> mdi-information-variant </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              outlined
              small
              v-else
              @click="setItem('info', true)"
            >
              Informações
              <v-icon right> mdi-information-variant </v-icon>
            </v-btn>
            <!-- BTN Rotas -->
            <v-btn
              class="ml-2"
              outlined
              small
              v-if="statusRota == true"
              color="blue"
              @click="setItem('route', false)"
            >
              Selecionar Rota
              <v-icon right> mdi-map-marker-radius </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              outlined
              small
              v-else
              @click="setItem('route', true)"
            >
              Selecionar Rota
              <v-icon right> mdi-map-marker-radius </v-icon>
            </v-btn>
          </v-card>
        </v-list-item>
      </v-row>
      <CardInfo v-bind:robo_id="this.$route.params.robo_id"></CardInfo>
    </v-col>
    <CameraRobo
      v-bind:robo_id="this.$route.params.robo_id"
      v-if="statusCam"
      v-bind:dns="dns"
    ></CameraRobo>
    <MapRobo
      v-bind:robo_id="this.$route.params.robo_id"
      v-if="statusMap"
    ></MapRobo>
    <InformacoesRobo
      v-bind:robo_id="this.$route.params.robo_id"
      v-if="statusInfo"
      v-bind:dns="dns"
    ></InformacoesRobo>
    <RotasRobo
      v-bind:robo_id="this.$route.params.robo_id"
      v-bind:map_id="3"
      v-if="statusRota"
      v-bind:dns="dns"
    ></RotasRobo>
  </div>
</template>

<script>
import service from "@/resources/dashboard/service";
import CameraRobo from "@/resources/dashboard/CameraRobo.vue";
import InformacoesRobo from "@/resources/dashboard/InfoRobo.vue";
import MapRobo from "@/resources/dashboard/Map/MapRobo.vue";
import RotasRobo from "@/resources/route_map/List.vue"; 
import CardInfo from "@/resources/dashboard/CardInfo.vue";

export default {
  components: {
    CameraRobo,
    InformacoesRobo,
    MapRobo,
    RotasRobo,
    CardInfo
  },
  data() {
    return {
      robos: [],
      statusCam: false,
      statusInfo: false,
      statusMap: false,
      statusRota: false,
      dns: null
    };
  },
  created() {
    this.getDadosRobo();
  },
  methods: {
    getDadosRobo() {
      service.getDadosRobo(this.$route.params.robo_id).then(value => {
        this.dns = value.dns;
      });
    },
    setItem(item, status) {
      this.statusCam = false;
      this.statusInfo = false;
      this.statusMap = false;
      this.statusRota = false;
      switch (item) {
        case "cam":
          this.statusCam = status;
          break;
        case "map":
          this.statusMap = status;
          break;
        case "info":
          this.statusInfo = status;
          break;
        case "route":
          this.statusRota = status;
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://static.robotwebtools.org/EventEmitter2/current/eventemitter2.min.js"
    );
    recaptchaScript.setAttribute(
      "src",
      "https://static.robotwebtools.org/roslibjs/current/roslib.min.js"
    );
  }
};
</script>
