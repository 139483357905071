<template>
  <div>
    <v-row justify="start">
      <v-col lg="3"
        ><v-autocomplete v-model="tpc" :items="comboTpc"></v-autocomplete>
      </v-col>
      <v-col lg="3"
        ><v-autocomplete
          v-model="type"
          :items="comboType"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-btn class="ma-4" outlined small @click="getTopics">
        OK
        <v-icon right> mdi-content-save </v-icon>
      </v-btn>
      <v-btn class="ma-4" outlined small @click="clearTopics">
        Limpar
        <v-icon right> mdi-notification-clear-all </v-icon>
      </v-btn>
    </v-row>
    <v-row justify="space-around">
      <v-col lg="6" v-for="(list, i) in listTopics" :key="list.id">
        <v-card>
          <v-card-title> Topic {{ list.title }} </v-card-title>
          <v-card-text style="font-size:10px">
            {{ list.text }}
          </v-card-text>
          <div class="text-end">
            <v-btn
              class="ma-4"
              fab
              dark
              small
              color="red"
              @click="removeTopic(i)"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ROSLIB from "roslib";
import service from "@/resources/dashboard/service";
export default {
  created() {
    this.getDadosRobo();
  },
  data() {
    return {
      listTopics: [],
      tpc: null,
      type: null,
      comboTpc: [],
      comboType: [],
      dns: null
    };
  },
  methods: {
    selectTpc() {
      alert("dsad");
    },
    removeTopic(i) {
      this.listTopics.splice(i, 1);
    },
    getTopics() {
      var vm = this;
      var contaisTopic;

      this.listTopics.forEach(element => {
        if (element.title == this.tpc) {
          contaisTopic = `Já possui um topico com nome de ${this.tpc}`;
          alert(contaisTopic);
        }
      });
      if (contaisTopic == null) {
        this.listTopics.push({
          id: this.listTopics.length,
          title: this.tpc,
          text: ""
        });
      }

      var ros = new ROSLIB.Ros({
        url: `ws://${this.dns}:9090`
      });

      var listener = new ROSLIB.Topic({
        ros: ros,
        name: this.tpc,
        messageType: this.type
      });

      listener.subscribe(function(message) {
        var data = JSON.stringify(message);
        console.log(message);
        vm.listTopics.forEach(element => {
          if (element.title == listener.name) {
            element.text =
              " - Received message on " + listener.name + ": " + data;
          }
        });
        console.log(
          "Received message on " + listener.name + ": " + message.data
        );
      });
    },
    clearTopics() {
      this.tpc = null;
      this.type = null;
    },
    getTypesTopics() {
      var vm = this;
      var ros = new ROSLIB.Ros({
        url: `ws://${this.dns}:9090`
      });
      var request = new ROSLIB.ServiceRequest();
      var topicTypeClient = new ROSLIB.Service({
        ros: ros,
        name: "/rosapi/topics",
        serviceType: "rosapi/Topics"
      });
      topicTypeClient.callService(request, function(result) {
        vm.comboTpc = result.topics;
        vm.comboType = result.types;
        console.log("Topics: ", result.topics); //topic names
        console.log("Topic Types: ", result.types); //topic types
      });
    },
    getDadosRobo() {
      service.getDadosRobo(this.$route.params.robo_id).then(value => {
        this.dns = value.dns;
        this.getTypesTopics();
        // this.infoRobo = value;
      });
    }
  },
  watch: {
    tpc(value) {
      for (let i = 0; i < this.comboTpc.length; i++) {
        if (value == this.comboTpc[i]) {
          this.type = this.comboType[i];
        }
      }
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "http://static.robotwebtools.org/EventEmitter2/current/eventemitter2.min.js"
    );
    recaptchaScript.setAttribute(
      "src",
      "http://static.robotwebtools.org/roslibjs/current/roslib.min.js"
    );
  }
};
</script>
