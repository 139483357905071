<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <base-material-card>
        <template v-slot:heading>
          <div class="display-2">
            {{ titleForm }}
          </div>
        </template>
        <Pointform :id="id" :item="item" @saved="onSaved"></Pointform>
      </base-material-card>
    </va-aside-layout>
    <base-material-card :title="this.title">
      <va-list
        resource="point"
        ref="list"
        :filters="filters"
        :include="includes"
        disable-create-redirect
        disable-global-search
        disable-create
      >
        <va-data-table
          :fields="fields"
          disable-show
          disable-create-redirect
          disable-edit-redirect
          disable-select
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import Pointform from "@/resources/map/points/Form.vue";
export default {
  props: ["resource", "mapa_id"],

  components: {
    Pointform
  },
  data() {
    return {
      includes: [{ api: `point/${this.mapa_id}` }],
      filters: [],
      fields: [
        { source: "description", sortable: true },
        {
          source: "coordinateX",
          label: "Coordenada X",
          type: "number",
          sortable: true
        },
        {
          source: "coordinateY",
          label: "Coordenada Y",
          type: "number",
          sortable: true
        },
        {
          source: "coordinateZ",
          label: "Coordenada Z",
          type: "number",
          sortable: true
        },
        {
          source: "coordinateW",
          label: "Coordenada W",
          type: "number",
          sortable: true
        }
      ],
      asideTitle: null,
      titleForm: "Points",
      title: "Points",
      id: null,
      item: null,
      show: false
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onAction({ title, id, item }) {
      this.asideTitle = " ";
      this.id = id;
      if (item == null) {
        var _item = {};
        for (let i = 0; i < this.fields.length; i++) {
          _item[this.fields[i].source] = null;
        }
        _item["maps_id"] = this.mapa_id;
        this.item = _item;
      } else {
        this.item = item;
      }
    },
    onSaved() {
      this.$root.$emit("atualizaPointsMap");
      this.asideTitle = null;
      this.$refs.list.fetchData();
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    this.$root.$on("newPointMap", value => {
      var _item = {};
      for (let i = 0; i < this.fields.length; i++) {
        _item[this.fields[i].source] = null;
      }
      _item.coordinateX = value.offsetX;
      _item.coordinateY = value.offsetY;
      _item.coordinateZ = value.pageX;
      _item.coordinateW = value.pageY;

      _item["maps_id"] = this.mapa_id;
      this.onAction({ title: "dadasd", item: _item });
    });
  }
};
</script>
