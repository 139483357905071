<template>
  <va-edit-layout>
    <v-col>
      <route-form :id="id" :title="title" :item="item"></route-form>

      <div v-if="this.item != null">
        <ListPath :route_id="id"></ListPath>
      </div>
    </v-col>
  </va-edit-layout>
</template>

<script>
import ListPath from "@/resources/route/path/List.vue";

export default {
  props: ["id", "title", "item"],
  components: { ListPath }
};
</script>
