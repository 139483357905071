<template>
 <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-text-input source="right" taggable required></va-text-input>
      <va-select-input
        source="Form_id"
        taggable
        chip
        :choices="comboForm"
        required
      ></va-select-input>
      <va-select-input
        source="user_id"
        taggable
        chip
        :choices="comboUser"
        required
      ></va-select-input>
      <va-select-input
        source="role_id"
        taggable
        chip
        :choices="comboRole"
        required
      ></va-select-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        active: false
      },
      comboForm: [],
      comboUser: [],
      comboRole: []
    };
  },
  created() {
    this.getForm();
    this.getUser();
    this.getRole();
  },
  methods: {
    getForm() {
      service
        .getDados({
          endpoint: "default/Form",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboForm = value;
        });
    },
    getUser() {
      service
        .getDados({
          endpoint: "default/User",
          slugId: "id",
          slugDesc: "login"
        })
        .then(value => {
          this.comboUser = value;
        });
    },
    getRole() {
      service
        .getDados({
          endpoint: "default/Role",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboRole = value;
        });
    }
  }
};
</script>
