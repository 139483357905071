<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-card-text>
      <va-text-input source="login" required></va-text-input>
      <va-text-input source="password" required></va-text-input>
      <va-date-input source="shiftIni" required></va-date-input>
      <va-date-input source="shiftFin" required></va-date-input>
      <va-select-input
        source="person_id"
        chip
        :choices="comboPerson"
        required
      ></va-select-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        active: false
      },
      comboPerson: []
    };
  },
  created() {
    this.getPerson();
  },
  methods: {
    getPerson() {
      service
        .getDados({
          endpoint: "default/Person",
          slugId: "id",
          slugDesc: "name"
        })
        .then(value => {
          this.comboPerson = value;
        });
    }
  }
};
</script>
