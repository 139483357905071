<template>
  <va-form
    resource="path"
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
  >
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-number-input source="sequence" taggable></va-number-input>
      <va-select-input
        source="route_id"
        chip
        :choices="comboRoute"
        taggable
      ></va-select-input>
      <va-select-input
        source="pointIni_id"
        chip
        :choices="comboPoint"
        taggable
      ></va-select-input>
      <va-select-input
        source="action_id"
        chip
        :choices="comboAction"
        taggable
      ></va-select-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["resource", "id", "title", "item"],
  data() {
    return {
      model: {
        active: false
      },
      comboRoute: [],
      comboPoint: [],
      comboAction: []
    };
  },
  created() {
    this.getRoute();
    this.getPoint();
    this.getAction();
  },
  methods: {
    getRoute() {
      service
        .getDados({
          endpoint: "default/Route",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboRoute = value;
        });
    },
    getPoint() {
      service
        .getDados({
          endpoint: "default/Point",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboPoint = value;
        });
    },
    getAction() {
      service
        .getDados({
          endpoint: "default/Action",
          slugId: "id",
          slugDesc: "description"
        })
        .then(value => {
          this.comboAction = value;
        });
    }
  }
};
</script>
