<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <template v-slot:heading>
      <div class="display-2">
        {{ title }}
      </div>
    </template>
    <v-card-text>
      <va-text-input source="description" taggable></va-text-input>
      <va-text-input source="inspectionHours" taggable></va-text-input>
    </v-card-text>
    <va-save-button label="Salvar"></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"]
};
</script>
