<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-global-search>
      <va-data-table :fields="fields"> </va-data-table>
    </va-list>
  </base-material-card>
</template>


<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        "id",
        "description",
        { source: "dateTimeIni", type: "date" },
        { source: "specialFlow", type: "number" },
        "frequency",
        { source: "maps_id", type: "select" },
        { source: "robot_id", type: "select" }
      ],
      fields: [
        { source: "id", sortable: true },
        { source: "description", sortable: true },
        { source: "dateTimeIni", type: "date", sortable: true },
        { source: "specialFlow", type: "number", sortable: true },
        { source: "frequency", sortable: true },
        { source: "maps_id", type: "select", sortable: true }
      ],
      asideTitle: null,
      titleForm: null,
      id: null,
      item: null,
      show: false
    };
  },
  created() {
    this.item = {
      dateTimeIni: new Date()
    };
  }
};
</script>
