<template>
  <base-material-card :icon="iconCamera" title="Imagem Robo">
    <v-card-text>
      <v-row justify="end" v-if="this.dns != null">
        <label>dns:{{ `http://${this.dns}:8080` }}</label>
        <v-list-item>
          <v-list-item-content>
            <JoysticRobo v-bind:dns="this.dns"></JoysticRobo>
          </v-list-item-content>
          <img :src="`http://${this.dns}:8080`" width="70%" />
        </v-list-item>
      </v-row>
      <div v-else>
        <label> Robo sem dns cadastrado!</label>
      </div>
    </v-card-text>
  </base-material-card>
</template>

<script>
import JoysticRobo from "@/resources/dashboard/Joystic/JoysticRobo.vue";

export default {
  props: ["dns"],

  components: {
    JoysticRobo
  },
  data() {
    return {
      iconCamera: "mdi-camera"
    };
  },
  mounted() {
    this.$root.$on("showCamera", value => {
      console.log(value);
    });
  }
};
</script>
