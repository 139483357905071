/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/"
  },
  { divider: true },
  {
    text: "Robo",
    children: [
      ...admin.getResourceLinks([
        "map",
        "robot",
        "route",
        "path",
        "point",
        "action",   
        "model",
        "topics"
      ])
    ]
  },
  {
    text: "Usuario",
    style: [{ textcolor: 'red' }],
    children: [
      ...admin.getResourceLinks([
        "user",
        "country",
        "person",
        "contact",
        "department",
        "form",
        "authorization",
        "state",
        "role",
      ])
    ]
  },
];
