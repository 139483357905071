<template>
  <va-create-layout>
    <authorization-form :title="title" :item="item"></authorization-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"]
};
</script>
