<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <va-field source="name"></va-field>
              <va-field source="cnpjCpf" type="number"></va-field>
              <va-field source="cep" type="number"></va-field>
              <va-field source="address"></va-field>
              <va-field source="number" type="number"></va-field>
              <va-field source="complement"></va-field>
              <va-field source="district"></va-field>
              <va-field source="department_id" type="select"></va-field>
              <va-field source="role_id" type="select"></va-field>
              <va-field source="state_id" type="select"></va-field>
              <va-field source="city"></va-field>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
