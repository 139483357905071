<template>
  <v-row justify="end">
    <v-col lg="4">
      <va-select-input
        v-model="department_id"
        label="Departamento"
        chip
        :choices="comboDepartment"
      ></va-select-input>
    </v-col>

    <v-btn color="green" class="ma-4"  small @click="saveDepartment">
      OK
      <v-icon right> mdi-check </v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import service from "@/resources/dashboard/service";

export default {
  data() {
    return {
      department_id: null,
      comboDepartment: []
    };
  },
  created() {
    this.getDepartment();
  },
  methods: {
    getDepartment() {
      service
        .getRobotDepartment({
          endpoint: `robotDepartment/${this.$route.params.robo_id}/params`,
          slugId: "department_id",
          slugDesc: "desc_department"
        })
        .then(value => {
          if (value.length > 0) {
            this.comboDepartment = value;
            this.department_id = value[0].value;
            this.$root.$emit("department_id", this.department_id);
          }
        });
    },
    saveDepartment() {
      this.$root.$emit("department_id", this.department_id);
    }
  }
};
</script>
