<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-text-input source="name"></va-text-input>
            <va-text-input source="phone"></va-text-input>
            <va-text-input source="email"></va-text-input>
            <va-text-input source="mainContact"></va-text-input>
            <va-select-input
              source="person_id"
              chip
              :choices="comboPerson"
              required
            ></va-select-input>
          </v-card-text>
          <va-save-button label="Salvar"></va-save-button>
        </base-material-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import service from "@/components/detail/service_combo";
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        active: false
      },
      comboPerson: []
    };
  },
  created() {
    this.getPerson();
  },
  methods: {
    getPerson() {
      service
        .getDados({
          endpoint: "default/Person",
          slugId: "id",
          slugDesc: "name"
        })
        .then(value => {
          this.comboPerson = value;
        });
    }
  }
};
</script>
