<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-global-search>
      <va-data-table :fields="fields"> </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: ["id", "description"],
      fields: [
        { source: "id", sortable: true },
        { source: "description", sortable: true },
        { source: "department_id", type: "select", sortable: true }
      ]
    };
  }
};
</script>
